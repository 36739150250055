<template>
    <comp-table
        edit-key="id"
        :columns="columns"
        table-api="/gateway/api/sycompany/pc/label/getLabelByPage?oemCode=zjsm&labelType=1"
        :delete-api="v => '/gateway/api/sycompany/pc/label/deleteById?labelById=' + v.id"
        :form-component="dataForm"
    >
        <template v-slot:search="data">
            <FormItem label="类型名称" :label-width="100" style="margin-bottom: 0">
                <Input v-model.trim="data.search.keyword" placeholder="请输入类型名称" style="width: 160px"></Input>
            </FormItem>
        </template>
    </comp-table>
</template>

<script>
import DataForm from "./form.vue"
import CompTable from "../components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            dataForm: DataForm,

            columns: [
                {
                    title: "类型名称",
                    key: "labelName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "排序",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("Input", {
                            props: {
                                value: params.row.sort,
                            },
                            attrs: {
                                type: "number",
                                number: "true",
                            },
                            style: {
                                width: "100px",
                            },
                            on: {
                                "on-enter": evt => {
                                    this.onChangeSort(params.row.id, evt.target.value)
                                },
                                "on-blur": evt => {
                                    this.onChangeSort(params.row.id, evt.target.value)
                                },
                            },
                        })
                    },
                },
                {
                    title: "编码",
                    key: "labelCode",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "创建时间",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.gmtCreate) {
                            return h("div", {}, "-")
                        }

                        return h("div", {}, this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss"))
                    },
                },
            ],
        }
    },

    methods: {
        /**
         * 修改排序
         */
        onChangeSort(id, val) {
            if (val < 0) {
                return this.$Message.error({
                    content: "排序值不能负数",
                    background: true,
                })
            }

            this.$post(`/gateway/api/sycompany/pc/label/updateLabelSeq?id=${id}&sort=${val}`).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                }
            })
        },
    },
}
</script>
<style lang="less"></style>
